import template from './+page.svelte'
import { get } from '@/service/api.js'

export default {
	template,
	resolve: async (data, parameters) => {
		console.log('Resolve for /rfc/:rfcId', { data, parameters })
		const [
			{ data: rfc, error: rfcError },
		] = await Promise.all([
			get(`/api/rfc?rfcId=${encodeURIComponent(parameters.rfcId)}`)
				.then(res => ({ data: res.body.data }))
				.catch(error => ({ error })),
		])
		console.log('ffffff', { rfc, rfcError })
		// if (!rfc || rfcError) return Promise.reject({
		// 	redirectTo: {
		// 		name: 'login',
		// 		params: { rfcId: parameters.rfcId },
		// 	},
		// })
		return {
			rfc,
		}
	},
}
