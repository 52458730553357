<script>
	import NotImplemented from '@/route/rfc/[rfcId]/NotImplemented.svelte'
	import Notice from '@/route/rfc/[rfcId]/Notice.svelte'

	export let asr
	export let rfc
	$: meta = rfc?.metadata || {}
	$: blocks = rfc?.value?.blocks || []
</script>

<style>
	.container {
		max-width: 80ch;
	}
</style>

<main>
<article>
<div class="container my-5">
	<header>
		<Notice {meta} />
		<h1 class="mb-4">{meta.title}</h1>
	</header>
	<section>
	{#each blocks as block, index}
		{#if block.name === 'html'}
			<div
				id={block.id || index}
				class={block.metadata?.class}
			>
				{@html block.content}
			</div>
		{:else}
			<NotImplemented {block} />
		{/if}
	{/each}
		</section>
</div>
</article>
</main>
