<script>
	export let meta
	const dateOptions = {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	}
</script>

<div class="card mb-4">
	<div class="card-body">
		<h5 class="card-title">
			RFC {meta.id}
			<span class="badge text-bg-secondary">{meta.status}</span>
		</h5>
		<p class="card-text">
			Last updated
			{new Date(meta.updated).toLocaleDateString(undefined, dateOptions)}
		</p>
	</div>
</div>
