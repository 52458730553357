<script>
	export let block
</script>

<div class="card">
	<div class="card-body">
		<h5 class="card-title">
			<em>Data type not implemented!</em>
		</h5>
		<code>name: {block.name}</code>
		{#if block.id}
			<br>
			<code>id: {block.id}</code>
		{/if}
		{#if block.metadata}
			<br>
			<code>metadata: {block.metadata}</code>
		{/if}
		<br>
		<code>content:</code>
		<br>
		<pre>{block.content}</pre>
	</div>
</div>
